.medform {
    position: relative;
}

.medform-container {
    padding: 45px 0px;
    margin: 220px 65px;
    background-color: #C5DACD;
    box-shadow: 3px 3px 3px rgb(63, 62, 62);
}

.medform-heading {
    text-align: center;
    background-color: #fff;
}

.medform-header {
    color: #fff;
    padding: 10px;
}

.medform-glamour--cols {
    display: table;
    width: 100%;
    height: 400px;
}

.medform-glamour--col {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    background-color: #923B34;
    color: #fff;
}

.medform-download {
    display: block;
    margin: 0 auto;
}

.medform-iconwrap {
    background-color: #C5DACD;
}

.medform-icon {
    display: block;
    margin: 0 auto;
    height: 320px;
    width: 450px;
}

.medform-download--icon {
    color: #C5DACD;
    font-size: 3.6em;
}

.medform-cols {
    display: table;
    width: 100%;
}

.medform-col {
    display: table-cell;
    width: 100%;
    text-align: center;
    vertical-align: middle;
}

.medform-item  {
   padding: 30px;
}

/* For form section */
.medform-label--sub {
    font-size: 2em;
    color: #fff;
    padding-bottom: 10px;
}
.form-container {
    display: block;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    background-color: #923B34;
    color: #fff;
}

hr {
    background-color: #fff;
    height: 10%;
}

.form-cols {
    display: table;
    width: 100%;
    padding: 6px;
}

.form-col {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    padding-bottom: 8px;
}

textarea {
    height: 100px;
}

input, textarea 
{
    padding: 10px;
    float: left;
    font-size: 1.4em;
    font-family: 'Work Sans', sans-serif;
}

label {
    font-family: 'Work Sans', sans-serif;
    font-size: 1.4em;
    text-align: center;
    font-weight: 500;
}


.medform-submit {
    padding: 1.4em;
    width: 100%;
    border: none;
    background-color: #3A8688;
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-size: 1.2em;
}

.error-text {
    font-size: 2em;
    color: #fff;
    font-weight: 900;
}

@media(max-width: 808px) {
    .medform-icon {
        display: block;
        margin: 0 auto;
        height: 270px;
        width: 100%;
    }

    .medform-container {
        padding: 0;
        margin: 155px 0px 100px 0px;
        width: 100%;
        box-shadow: 3px 3px 3px rgb(63, 62, 62);
    }

    .medform-glamour--col {
        display: block;
        width: 100%;
    }

    .form-container {
        width: 100%;
    }

    .form-cols {
        display: table;
        width: 100%;
    }

    .form-col {
        display: block;
        width: 100%;
    }
    input, textarea 
    {
        padding: 0;
        float: none;
        text-align: left;
        width: 100%;
    }

    label {
        float: none;
        text-align: center;
        width: 100%;
    }
}


