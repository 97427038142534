.services {
    position: relative;
}

.services-heading {
    text-align: center;
    background-color: #923B34;
    padding: 15px;
}

.services-container {
    padding: 0;
    margin: 220px 65px;
    box-shadow: 6px 6px 6px rgb(63, 62, 62);
}

.services-cols {
    display: table;
    width: 100%;
    background-color: #fff;
}

.services-col {
    display: table-cell;
    width: 33.3%;
}

/* .services-item {} */

.services-content {
    padding: 20px;  
}

.services-content img {
    width: 100%;
    border: 5px solid #923B34; 
}
.services-col--payment {
    display: table-cell;
    width: 100%;
}
.services-text {
    background-color: #fff;
    padding: 10px;
}

.services-header {
    text-align: center;
    background-color: #923B34;
    padding: 10px;
    color: #fff
}

.services-heading {
    color: #fff;
}

.services-section {
    background-color: #fff;
}

.services-payment-container {
    text-align: center;
    color: #923B34;
}

.services-paypal {
    text-align: center;
}


@media(max-width: 808px) {

    .services-container {
        padding: 0;
        margin: 155px 0px;
    }
    .services-col {
        display: block;
        width: 100%;
    }

    .services-content {
        padding: 10px;
        text-align: center;
    }
}

@media(max-width: 480px) {
    
}