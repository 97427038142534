.about {
  position: relative;
}

.about-container {
  padding: 35px;
  margin: 220px 65px;
  background-color: #C5DACD;
}

.about-cols {
  display: table;
  width: 100%;
  
}

.about-heading {
  display: block;
  margin: 0 auto;
  background-image: url("./images/about-us.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 170px;
  padding: 10px;
  text-align: center;
  max-width: 450px;
}

.about-col {
  display: block;
  width: 100%;
}

.about-item {
  padding: 15px;
  
}

.about-header {
  padding: 10px;
}

.about-social a {
  color: #923B34;
  padding: 10px;
  font-size: 2.7em;
}

.about-img {
    display: block;
    margin: 0 auto;
    background-color:#923B34;
    border:solid 40px #eee;
    border-bottom-color:#fff;
    border-left-color:#eee;
    border-radius:2px;
    border-right-color:#eee;
    border-top-color:#ddd;
    box-shadow:0 0 2px 0 rgba(0,0,0,.25) inset, 0 5px 5px 5px rgba(0,0,0,.25);
    padding: 20px;
    position:relative;
    text-align:center;
}

.about-photo {
  height: auto;
  width: 100%;
}

.about-content {
  background-color: #fff;
  text-align: center;
  padding: 15px;
}

.about-social--heading {
  padding: 10px;
  background-color: #fff;
}

.about-text--wrap {
  display: block;
  margin: 0 auto;
  padding-top: 15px;
  max-width: 700px;
  text-align: left;
}

@media(max-width: 808px) {
  .about-container {
    padding: 0;
    margin: 155px 0px 100px 0px;
  }
  .about-img {
    border:solid 20px #eee;
    border-bottom-color:#fff;
    border-left-color:#eee;
    border-radius:2px;
    border-right-color:#eee;
    border-top-color:#ddd;
  }

}


