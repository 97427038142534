.main-container {
    margin: 135px 0px;
}

/* ////////////////////
Landing page selectors
/////////////////// */

.landing-page {
    position: relative;
}

.landing-page--container {
    margin: 235px 0px;
}

.landing-glamour {
    position: relative;
}

.landing-glamour--container {
    margin: 0;
    padding: 30px 0px;
}

.landing-glamour--cols {
    display: table;
    width: 100%;
}

.landing-glamour--col {
    display: table-cell; 
    
}

.landing-glamour--logo {
  height: 75px;
  width: 95px;
}

.landing-gallery {
    position: relative;
    width: 100%;
}

.landing-gallery--container {
    margin: 0px 65px;
    padding: 0;
}

/* ////////////////////
Bootstrap selectors
/////////////////// */
.carousel-item img {
    height: 750px;
    width: 100%;
    border: none;
    box-shadow: 2px 2px 2px rgb(63, 62, 62);
}


.message-board {
    position: relative;
    text-align: center;
}

.message-container {
    margin: 0px 65px;
    padding: 45px 0px;
    background-color: #fff;
    box-shadow: 2px 2px 2px rgb(63, 62, 62);
}

.message-cols {
    display: table;
    width: 100%;
}

.message-col--left {
    display: table-cell;
    width: 100%;
    padding: 15px;
}

.message-item {
  padding: 20px;
}

.landing-info {
    position: relative;
}

.landing-info--container {
    padding: 40px 0px;
    margin: 0px 65px;
    background-color: #fff;
    border: 5px solid #923B34;
    box-shadow: 2px 2px 2px rgb(63, 62, 62);
}

.landing-info  a {
    cursor: pointer;
    font-size: 2.8em;
    color: #923B34;
}

.location-header {
    padding: 10px 15px;
    color: #923B34;
}

.landing-cols {
    display: table;
    width: 100%;
}

.landing-col {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    text-align: center;
}

.landing-ul {
    padding: 15px;
    background-color: #fff;
}

.landing-ul li {
    list-style-type: none;
    color: #923B34;
    padding-top: 6px;
    font-size: 1.4em;
    font-family: 'Work Sans', sans-serif;
}

.landing-divider {
    display: block;
    margin: 0 auto;
    width: 50%;
    background-color: #923B34;
}

/* ////////////////////
Google Map
/////////////////// */
.map-border {
    border: 4px solid #923B34;
}

iframe {
    padding: 6px;
    height: 620px;
    width: 100%;
}

.fab {
    display: block;
    padding: 5px;
    color: #923B34;
    font-size: 3em;
}

/* ////////////////////
Site fonts
/////////////////// */

h4, h3, h5, h6 {
    color: #923B34;
    font-family: 'Cabin', sans-serif;
}

p {
    font-size: 1.4em;
    font-weight: 200;
    font-family: 'Work Sans', sans-serif;
}

li {
    font-size: 1.4em;
    font-family: 'Work Sans', sans-serif; 
}

@media(max-width: 480px) {

    h4 {
        text-align: center;
    }

    p {
        font-size: 1.4em;
    }

    li {
        font-size: 1.2em; 
    }
}

/* End of Font */

@media(max-width: 808px) {

    .main-container {
        margin: 135px 0px 100px 0px;
    }

    .landing-page--container {
        margin: 125px 0px 50px 0px;
    }
    
    .landing-gallery--container {
        margin: 0px;
        padding: 0px;
    }
    .carousel-item img {
        height: 450px;
        width: 100%;
    }

    .location-header {
        text-align: center;
    }

    .landing-col {
        display: block;
        width: 100%;
    }

    .message-container {
        margin: 0px ;
        padding: 0px;
        box-shadow: none;
    }
    
    .message-col--right {
        display: block;
        width: 100%; 
    }
    .message-col--left {
        display: block;
        width: 100%; 
    }
    .landing-glamour--container {
        margin: 0px 75px;
        padding: 45px 0px;
    }
    
    .landing-glamour--col {
        display: block;
        width: 100%; 
    }

    .landing-glamour--logo {
        height: auto;
        width: 100%;
     }

    .landing-info--container {
        padding: 0;
        margin: 0;
    }

    .landing-content {
        display: block;
        width: 100%;
    }
    iframe {
        padding: 0;
        height: 280px;
        width: 100%;
    }
  }


@media(max-width: 480px) {

    .carousel-item img {
        height: 300px;
        width: 100%;
    }

    .location-header {
        text-align: center;
    }

    .landing-col {
        display: block;
        width: 100%;
    }

    .landing-content {
        display: block;
        width: 100%;
    }

    .landing-content iframe{
        display: block;
        margin: 0 auto;
    }
}

