.emergency {
    position: relative;
}

.emergency-container {
    padding: 0;
    margin: 220px 65px;
    background-color: #fff;
    box-shadow: 3px 3px 3px rgb(63, 62, 62);
}

.emergency-heading {
    background-color: #923B34;
}

.emergency-header--main {
    color: #fff;
    padding: 15px;
    text-align: center;
}

.emergency-cols {
    display: table;
    width: 100%;
}

.emergency-col {
    display: table-cell;
    vertical-align: middle;
    width: 50%;
}

.emergency-item {

}

.emergency-item img {
    height: 550px;
    width: 100%;
    border: 8px solid #923B34;
}

.emergency-content {
    padding: 10px;
}

.emergency-header {
    font-size: 1.9em;
    color: #923B34;
    padding: 8px;
}

.apply_btn button {
    display: block;
    margin: 0 auto;
    height: 35px;
    width: 125px;
    padding: 8px;
    font-size: .8em;
    color: #fff;
    background-color: #923B34;
    outline: none;
}

@media(max-width: 808px) {

    .emergency-container {
        padding: 0;
        margin: 150px 0px;
    }
    
    .emergency-cols {
    
    }
    
    .emergency-col {
        display: block;
        width: 100%;
    }
    
    .emergency-item {
    
    }

    .emergency-item img {
        height: 350px;
        width: 100%;
        border: 8px solid #923B34;
    }
    
    
    .emergency-content h4, li{
        text-align: center;
    }

    .emergency-content ul {
        display: block;
        margin: 0 auto;
        max-width: 215px;
    }

}