.dashboard {
    position: relative;
}

.dashboard-container {
    padding: 45px 0px;
    margin: 220px 65px;
    background-color: #923B34;
    box-shadow: 2px 2px 2px rgb(63, 62, 62);
}

.dashboard-header {
    text-align: center;
}

.dashboard-header--cols {
    display: table;
    width: 100%;
}

.dashboard-header--col {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
}
.dashboard-header {
    color: #fff;
}
.dashboard-content {
    padding: 10px;
}


.dashboard-info {
    position: relative;
}

.dashboard-info--content {
    background-color: #923B34;
    text-align: center;
    color: #fff;
    padding: 10px;
}
.dashboard-info--cols {
    display: table;
    width: 100%;
}

.dashboard-info--col {
    background-color: #C5DACD;
    display: table-cell;
    width: 50%;
    vertical-align: top;
}

.dashboard-info--col:nth-child(1){
    border-left: 5px solid #923B34;
    border-right: 5px solid #923B34;
}

.dashboard-info-wrapper {
    padding: 10px;
    background-color: #3A8688;
   text-align: center;
}

.dashboard-info-img {
    display: inline-block;
    height: 230px;
    max-width: 70%;
}

.dashboard-button {
    text-align: center;
    padding: 1.4em;
    width: 100%;
    border: none;
    background-color: #3A8688;
    color: #fff;
    font-family: 'Work Sans', sans-serif; 
    font-size: 1.2em;
}
.dashboard-header--col {
 
}
.post-message {
    text-align: center;
    position: relative;

}
.post-heading {
    font-size: 2em;
    color: #fff;
}

.post-textarea {
    font-family: 'Work Sans', sans-serif; 
    font-size: 1.4em;
    font-weight: normal;
    height: 300px;
    width: 100%;
}

.post-container {
    width: 100%;
    background-color: #923B34;
    padding: 8px 0px;
    margin: 25px 0px;
}

.register-cols {
    display: table;
    width: 100%;
    background-color: #923B34;
    color: #fff;
}

.register-col {
    display: table-cell;
    width: 50%;
    padding: 8px;
}

.dashboard-user {
    font-size: 2em;
}

@media(max-width:808px) {
    .dashboard-container {
        padding: 0px;
        margin: 155px 0px 100px 0px;
        box-shadow: 2px 2px 2px rgb(63, 62, 62);
    }
    .dashboard-info--col {
        display: block;
        width: 100%;

    }
    .dashboard-content {
        text-align: center;
        padding: 20px;
    }
    
    .dashboard-info--col:nth-child(1){
        border: none;
    }
}