/* ////////////////////
Footer 
/////////////////// */

footer {
    width: 100%;
    background-color: #923B34;
    text-align: center;
    background-image: url("./images/nav-menu-design.png");
    background-position: 20% 30%; 
    border-top: solid .08px rgb(65, 62, 62);
}

.footer-container {
    margin: 40px 40px 0px 40px;
}

.footer-ul {
    padding: 0;
}

.footer-ul li {
    display: inline-block;
    margin: 0px 8px;
}

.footer-links a {
    font-size: 2.5rem;
    text-decoration: none;
    color: #fff;

}

/* ////////////////////
Footer columns and column-cells
/////////////////// */

.footer-cols {
    display: table;
    width: 100%;
}

.footer-col {
    display: table-cell;
    vertical-align: middle;
    height: 100px;
    width: 33.3%;
    font-size: 1em;
}

.footer-item {
    position: relative;
    padding: 8px;
    color: #fff;
}

/* ////////////////////
Footer logos
/////////////////// */

.footer-logo {
    font-size: 1em;
    color: #fff;
    cursor: pointer;
}


.footer-logo--co {
    color: #fff;
    height: 100px;
    width: 200px;
}

.cd-logo {
    height: 100px;
    width: 150px;
    padding-bottom: 10px;
}

#spanYear {
    color: #fff;
}

/* ////////////////////
Media queries for mobile breakpoint
/////////////////// */

@media(max-width: 808px) {
    .footer-col {
        display: block;
        width: 100%;
    }

    .footer-container {
        padding: 10px 0px;
        margin: 0px 20px;
    }

    .footer-item {
        margin: 12px 0px;
    }

    .footer-links a {
        font-size: 1.8rem;
        text-decoration: none;
        color: #fff;
        text-align: center;
    
    }

}

@media(max-width: 380px) {

    .footer-container {
        padding: 0;
        margin: 0;
    }

}