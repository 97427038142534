.nav-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #C5DACD;
    width: 100%;
    box-shadow: 1px 1px 2px rgb(63, 62, 62);
}

.nav-container {
    padding: 0;
    margin: 0px 55px;
}

.nav-cols {
    display: table;
    width: 100%;
}

.nav-col {
    display: table-cell;
    width: 33.3%;
    vertical-align: middle;
}

.nav-logo {
    display: block;
    margin: 0 auto;
    height: 175px;
    width: 220px;
    
}

.nav-fixed span {
    font-size: 4.6em;
    padding: 10px;
    color: #923B34;
}

.nav-slide {
    display: none;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #C5DACD;
    z-index: 9999;
    text-align: center;
    opacity: .97;
  }

  .nav-content {
    position: absolute;
    top: 10%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .nav-links {
      position: relative;
      text-align: center;
      height: 1400px;
      background-color: #923B34;
      background-image: url('./images/nav-menu-design.png');
      background-repeat: no-repeat;
      background-position: 40% 60%; 
  }

  
    .close {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: -16%;
        left: 0;
        right: 0%;
        font-size: 3.4rem;
    }

    .close:hover {
        color: #fff;
    }

  .nav-link {
      text-decoration: none;
      font-size: 2.5em;
      font-family: 'Cabin', sans-serif;
      margin: 0px 0px 15px 0px;
      color: #fff;
  }

  .nav-link:hover {
     color: #fff;
     text-decoration:none; 
     cursor:pointer;  
}

  .open {
   display: block;
   width: 100%;
  }

  .open:hover {
      cursor: pointer;
  }
  
/* .nav-item {} */

@media(max-width: 808px) {

    
    .nav-container {
        padding: 6px 0px;
        margin: 0px 25px;
    }

    .nav-logo {
        display: block;
        margin: 0 auto;
        height: 140px;
        width: 165px;
    }

    .nav-content {
        top: 7%;
      }

      .nav-links {
        height: 1350px;
    }

    .nav-link {
       margin: 0px 0px 9px 0px;
       padding: 15px 0px;
       font-size: 2em;
    }

    .nav-fixed span {
        font-size: 3.6em;
        padding: 6px;
        color: #923B34;
    }
}

@media(max-width: 480px) {
    .nav-fixed span {
        font-size: 3.6em;
        padding: 5px;
        color: #923B34;
    }

    .nav-logo {
        height: 128px;
        width: 160px;
    }
}


