.form-cols {
    display: table;
    width: 100%;
    padding: 6px;
}

.form-col {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    padding-bottom: 8px;
}

.enrollment-form {
  position: relative; 
}

.enrollment-dates {
    display: none;
}

.enrollment-date {
    margin: 4px;
}

.enrollment-heading {
    color: #fff;
    width: 100%;
}

.enrollment-header {
    padding: 10px;
    color: #fff;
}

.enrollment-container {
    padding: 0px;
    margin: 250px 65px;
    background-color: #923B34;
    color: #923B34;
    text-align: center;
    box-shadow: 3px 3px 3px rgb(63, 62, 62);
  
}

.enroll-form--container {
    padding: 45px 0px 0px 0px;
    margin: 0px 85px;
    background-color: #C5DACD;
 
}

.enrollment-cols {
    display: table;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid #923B34;
    
}

.enrollment-col {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    padding: 4px;
}

.enrollment-col input {
    border: 2px solid #923B34;
    padding-bottom: 4px;
    text-align: center;
    width: 75%;
}

.enrollment-pdf-download i {
    padding: 8px;
    font-size: 3.2em;
    color: #C5DACD;
}

.enrolllment-questionnaire {
    font-style: italic;
    font-size: 1.1em;
    font-weight: 900;
}

textarea.form-control {
    width: 75%;
    height: 120px;
    border: 2px solid #923B34;
}

 textarea 
    {
        padding: 5px;
        float: left;
        font-size: 1.6em;
        font-family: 'Work Sans', sans-serif; 
    }

label {
    font-family: 'Work Sans', sans-serif; 
    font-size: 2.2em;
    text-align: center;
    font-weight: 800;
}

.form-check-input {
   position: relative;
}

.checkbox-cols {
    display: table;
    width: 100%;
    padding: 8px;
   
}

.checkbox-col {
    display: table-cell;
    text-align : center;
    padding: 6px;
}

.enroll-checkbox-text {
    padding: 2px;
    font-size: 1.4em;
    text-align: center;
}

.enroll-check {
    border: 2px solid #923B34;
    font-size: 1.4em;
}

  input[type='checkbox'] {
    display: block;
    margin: 0 auto;
    position: relative; 
  }
  .alert {
    width: 400px;
    transition: 2s fadeout;
    border: #000 .5px solid;
    background-color: #AFD19F;
    font-size: 1.8em;
    font-style: italic;
    color: #fff;
    display: none;
  }
  .enrollment-button {
    text-align: center;
    padding: 1.4em;
    width: 100%;
    border: none;
    background-color: #3A8688;
    color: #fff;
    margin-top: 15px;
    font-family: 'Work Sans', sans-serif; 
    font-size: 1.2em;
  }


@media(max-width: 808px) {
    .enrollment-form {
        width: 100%;
      }
      
      .enrollment-heading {
          color: #fff;
          width: 100%;
      }
      
      .enrollment-header {
          padding: 10px;
          color: #fff;
      }

      .enrollment-dates {
          display: block;
      }
      
      .enrollment-container {
          width: 100%;
          padding: 0px;
          margin: 155px 0px 100px 0px;
      }

      form {
         width: 100%;
      }
      
      .enroll-form--container {
          display: block;
          width: 100%;
          padding: 0;
          margin: 0;
      }
      
      .enrollment-col {
          display: block;
          width: 100%;
      }
      
      .enrollment-col input {
          border: 2px solid #923B34;
          width: 100%;
      }

      .enrollment-textarea {
        border: 2px solid #923B34; 
      }
      
      .enrollment-pdf-download i {
          padding: 8px;
          font-size: 3.2em;
          color: #C5DACD;
      }

      textarea.form-control {
        width: 100%;
        height: 130px;
     
    }
    
    input[type='checkbox'] {
        display: block;
        margin: 0 auto;
        position: relative; 
      }

    .enroll-checkbox-text {
        font-size: 1.2em;
    }

    label {
        text-align: center;
    }

    .enroll-check {
        text-align: center;
    }

    .alert {
        width: 300px;
        transition: 2s fadeout;
        border: #000 .5px solid;
        background-color: #AFD19F;
        font-size: 1em;
        font-style: italic;
        color: #fff;
        display: none;
      }
}
