.login {
    position: relative;

}

.login-container {
   padding: 30px 0px;
   margin: 130px 65px;
   background-color: #923B34;
   box-shadow: 1px 1px 2px 1px rgb(63, 62, 62);
}

.login-form-container {
    padding: 30px 0px 10px 0px;
    margin: 0px 50px;
    border: .3px solid rgb(63, 62, 62);;
    background-image: url("../layout/images/nav-menu-design.png");
    background-position: 20% 30%; 
}

.login-cols {
    display: table;
    width: 100%;
}

.login-col {
    display: table-cell;
    width: 12%;
    vertical-align: middle;
    padding: 15px;
}

.login-label {
    font-size: 1.6em;
    color: #fff;
}

.login-link {
    text-decoration: none;
    font-size: 1.7em;
    color: #923B34;
}

.login-heading {
    background-color: #fff;
}

.login-header {
    text-align: center;
}

.login-heading-container {
    padding: 10px 0px;
    margin: 0px 65px;
}

@media(max-width: 808px) {
    .login {
        position: relative;
    
    }
    
    .login-container {
       padding: 30px 0px;
       margin: 130px 0px;
    }
    
    .login-form-container {
        padding: 30px 0px 10px 0px;
        margin: 0px 40px;
        border: .3px solid rgb(63, 62, 62);;
    }
    
    .login-cols {
        display: table;
        width: 100%;
    }
    
    .login-col {
        display: block;
        width: 100%;
    }
    
    .login-label {
        font-size: 1.6em;
        color: #fff;
    }
    
    .login-link {
        text-decoration: none;
        font-size: 1.2em;
        color: #923B34;
    }
    
    .login-heading {
        background-color: #fff;
    }
    
    .login-header {
        text-align: center;
    }
    
    .login-heading-container {
        padding: 10px 0px;
        margin: 0px 0px;
    }
    
}

