.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; 
    padding-top: 25px;
    height: 0;
    width: 100%;
    border: 6px solid #C5DACD;
    box-shadow: 2px 2px 2px rgb(63, 62, 62);
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoPlayer {
    padding: 45px 0px;
    margin: 0px 65px;
}

@media(max-width: 808px){
    .videoPlayer {
        padding: 0;
        margin: 0;
    }   
}